<template>
  <div class="page">
    <ls-title-header @back="cancel" title="记录详情" ></ls-title-header>
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="180px" class="demo-ruleForm">
      <div style="background-color: white;">
        <div class="box">

          <el-row class="row">
            <el-col :span="12">
                <el-form-item>
                  <template #label><span class="title">姓&emsp;&emsp;名</span></template>
                  <span class="content">{{recordData.name}}</span>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item>
                  <template #label><span class="title">身&ensp;份&ensp;证</span></template>
                  <span class="content">{{recordData.idcard}}</span>
                </el-form-item>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="12">
                <el-form-item>
                  <template #label><span class="title">性&emsp;&emsp;别</span></template>
                   <span class="content" v-if="recordData.sex == 1">男</span>
                   <span class="content" v-if="recordData.sex == 0">女</span>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item>
                  <template #label><span class="title">年&emsp;&emsp;龄</span></template>
                   <span class="content">{{recordData.age}}</span>
                </el-form-item>
            </el-col>
          </el-row>

          <el-row class="row">
            <el-col :span="12">
                <el-form-item>
                  <template #label><span class="title">民&emsp;&emsp;族</span></template>
                   <span class="content">{{recordData.nation}}</span>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item>
                  <template #label><span class="title">出生年月</span></template>
                   <span class="content">{{recordData.birthday}}</span>
                </el-form-item>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="12">
                <el-form-item>
                  <template #label><span class="title">居住地址</span></template>
                   <span class="content">{{ recordData.address != "" ? recordData.address.replace(/#/g, "") : "--"}}</span>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item v-if="!editShow">
                  <template #label><span class="title">用户状态</span></template>
                  <span class="content" v-if="recordData.userState==1">可见</span>
                  <span class="content" v-if="recordData.userState==0">不可见</span>
                </el-form-item>
                <el-form-item v-if="editShow">
                  <template #label><span class="title">用户状态</span></template>
                  <el-select v-model="ruleForm.userState" placeholder="用户状态" size="large" style="margin-left:5px;">
                    <el-option v-for="item in userStateOption" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="12">
              <el-form-item prop="recorder">
                  <template #label><span class="title">记&ensp;录&ensp;人</span></template>
                  <span v-if="!editShow" class="content">{{recordData.recorder}}</span>
                  <el-input v-if="editShow" v-model="ruleForm.recorder" placeholder="请输入记录人" maxlength="10" show-word-limit class="input"></el-input>
                </el-form-item>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="24">
                <el-form-item prop="desction">
                  <template #label><span class="title">信息内容</span></template>
                  <span v-if="!editShow" class="content">{{recordData.desction}}</span>
                  <el-input v-if="editShow" type="textarea" v-model="ruleForm.desction" placeholder="请输入信息内容" maxlength="200" show-word-limit class="input"></el-input>
                </el-form-item>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="24" v-if="!editShow">
                <el-form-item v-if="recordData.imageList.length != 0">
                  <template #label><span class="title">记录图片</span></template>
                  <el-image v-for="image in recordData.imageList" :key="image.url" :src="image.url" :preview-src-list="srcList" style="width:150px;height:100px;"></el-image>
                </el-form-item>
                <el-form-item v-if="recordData.imageList.length == 0">
                  <template #label><span class="title">记录图片</span></template>
                  <span>无图片</span>
                </el-form-item>
            </el-col>
            <el-col :span="24" v-if="editShow">
                <el-form-item>
                  <template #label><span class="title">记录图片</span></template>
                  <ls-file-upload :uploadUrl="getUploadUrl()" :limitCount="3" :data="fileData" :files="fileList" @uploadSuccess="uploadSuccess" @deleteFile="deleteImage"></ls-file-upload>
                </el-form-item>
            </el-col>

          </el-row>

        <el-row class="row">
          <el-col :span="24">
              <el-form-item>
                <el-button v-show="!editShow&role!=1" type="primary"  size="small" @click="editShow=true">编辑</el-button>
                <el-button v-show="editShow&role!=1" type="primary" size="small" @click="closeEdit" plain>取消</el-button>
                <el-button v-show="editShow&role!=1" type="primary"  size="small" @click="submitForm('ruleForm')">提交</el-button>
              </el-form-item>
          </el-col>
        </el-row>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import session from "../../../store/store";
import {getUserRecordInfo,editUserRecordInfo,fileUpload,fileDelete} from '../../../api/api'
export default {
  data() {
    return {
      editShow:false,
      role:0,
      uuid:'',
      fileList:[],//页面显示
      imageList:[],//上传需要
      fileData:{},
      recordData:{
        address: '',
        imageList:[],
      },
      srcList:[],
      ruleForm: {
        userUuid:'',
        userState:1,
        recorder:'',
        desction:'',
      },
      userStateOption:[{
          value: 1,
          label: '可见'
        },{
          value: 0,
          label: '不可见'
        }
      ],
      rules: {
        recorder: [
          {required: true,message: '请输入记录人', trigger: 'blur',}
        ],
        desction: [
          {required: true,message: '请添加信息', trigger: 'blur',}
        ],
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.role = session.getLoginInfo().accountType;
      this.fileData.directory = session.getLoginInfo().unitUuid
    },
    getParentData(data) {
      this.uuid = data.uuid;
      this.getUserRecordInfo();
    },

    getUserRecordInfo() {
      let param = {
        uuid:this.uuid,
      }

      getUserRecordInfo(param).then(res => {
        if (res.data.code == 200) {
          this.recordData = res.data.dataInfo;
          this.ruleForm = this.recordData;
          this.srcList.splice(0,this.srcList.length)
          this.fileList.splice(0,this.fileList.length)
          this.imageList.splice(0,this.imageList.length)
          this.recordData.imageList.forEach(data => {
            this.srcList.push(data.url)
            this.fileList.push({filePath:data.filePath,url:data.url});
            this.imageList.push(data)
          });
        }
      })
    },
    cancel() {
      this.$emit('cancel')
    },
    close() {
      this.$emit('update')
    },

    submitForm(ruleName) {
      this.$refs[ruleName].validate((valid) => {
        if (valid) {
          this.editRecord();
        } else {
          return false;
        }
      });
    },

    editRecord() {
      var param = {
        uuid:this.recordData.uuid,
        unitUuid:session.getLoginInfo().unitUuid,
        userUuid:this.ruleForm.userUuid,
        desction:this.ruleForm.desction,
        appShowSwitch:this.ruleForm.userState,
        recorder:this.ruleForm.recorder,
      }

      if(this.imageList.length > 0) {
        param.imageInfos = JSON.stringify(this.imageList);
      }

      editUserRecordInfo(param).then(res => {
        if (res.data.code == 200) {
          this.$message.success({ message: '修改成功'});
          this.closeEdit();
          this.close();
          this.getUserRecordInfo();
        } else {
          this.$message.error({ message: res.data.message});
        }
      })
    },
    closeEdit() {
      this.editShow = false;
    },


    getUploadUrl() {
      return fileUpload();
    },
     // 图片上传成功
    uploadSuccess(e) {
      this.imageList.push(e.dataInfo)
    },
    //图片删除
    deleteImage(e, callback) {
      let param = {
        filePath:e.response.filePath,
      }
      fileDelete(param).then(res => {
        if (res.data.code == 200) {
          this.imageList.forEach((data,index) => {
            if(data.filePath == e.response.filePath) {
              this.imageList.splice(index,1)
              return;
            }
          });
          callback(res);
        }
      })
    },


 
  }
}
</script>

<style lang="scss" scoped>

.page {
  padding: 0px 20px;
}
.el-form-item {
  margin-bottom: 0px !important;
  margin-left: -19px;
}
.input {
  width: 90%;
}
hr {
  border-color: #f4f4f526;
}
.page {
  padding: 0px 20px;
}
.title {
  margin-right: 25px;
  font-size: 16px;
  color: #8D8D8D;
}
.box {
  padding: 20px;
  width: 75%;
  background-color: white;
}
.content {
  font-size: 16px;
  margin-left: 10px;
}
.row {
  margin: 20px 0px 20px 0px;
}
</style>